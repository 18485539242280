/*=============================================
=            category-page             =
=============================================*/


.category-page {
    display: flex;
    flex-direction: column;
    align-self: start;
    width: 100%;

    overflow: auto;

    &__title {
        color: $black--seven;
        text-align: left;
        padding-left: 15px;
        padding-bottom: 15px;
        font-size: 1.4em;
    }
};


.category-packs {

    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;



    &__title {
        text-align: left;
        font-weight: 500;
        font-size: 1.2em;
        padding: 15px;
        margin: 0;
    }

    &__items {
        display: grid;
        grid-template-rows: 1fr;
        grid-auto-flow: column;
        justify-items: center;

        width: calc(100% - 30px);
        margin: 0 auto;
        background-color: $grey--three;
        box-shadow: 0 4px 6px rgba($black, 0.1);
        overflow-x: auto;
        padding: 15px;

        .category-pack-item {
            display: grid;
            grid-template-rows: 150px;
            grid-template-columns: 1fr;
            width: 150px;
            min-width: 150px;
            user-select: none;
            cursor: pointer;
            flex-wrap: nowrap;
            &__image {
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
            }
        }
    }


    &::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: $white;
    }

    &::-webkit-scrollbar
    {
        width: 6px;
        background-color: $white;
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: $sugarlicious-secondary;
    }
}



.category-individuals {

    overflow: hidden;

    &__title {
        text-align: left;
        font-weight: 500;
        font-size: 1.2em;
        padding: 15px;
        margin: 0;
    }

    &__info {
        grid-row: 2;
        grid-column: 2;
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-rows: minmax(0, 1fr) 40px;
        grid-template-columns: 1fr;
        overflow: hidden;
    }

    &__info_text {
        width: 100%;
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: $white;
        }

        &::-webkit-scrollbar
        {
            width: 6px;
            background-color: $white;
        }

        &::-webkit-scrollbar-thumb
        {
            background-color: $sugarlicious-secondary;
        }
    }

    &__item_head {
        grid-row: 1;
        grid-column: 1 / span 2;

        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
        padding-left: 15px;
        padding-top: 5px;
        border-bottom: 1px dotted $grey--eight;
    }
    
    &__item_info {
        cursor: pointer;
        color: $sugarlicious-secondary;
        font-weight: 600;
    }

    &__item_title {
        text-align: left;
        font-weight: 500;
        font-size: 1.2em;
        margin: 0;
    }

    &__item_price {
        margin: 0;
        text-align: right;
        font-weight: 500;
        font-size: 1.2em;
        padding-right: 15px;
    }

    &__description {
        text-align: left;
        padding-top: 5px;
        padding-left: 5px;
        padding-right: 5px;
    }

    &__items {
        display: grid;
        grid-template-rows: auto 150px;
        grid-template-columns: auto minmax(0, 1fr);

        width: calc(100% - 30px);
        background-color: $grey--three;
        box-shadow: 0 4px 6px rgba($black, 0.1);
        overflow-x: auto;
        margin: 0 auto 15px;

        &__image {
            height: 100%;
            width: 150px;
            min-width: 150px;
            grid-column: 1;
            grid-row: 2;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
        }

        .category-individual-item {
            display: grid;
            grid-template-rows: 150px;
            grid-template-columns: 1fr;
            width: 150px;
            min-width: 150px;
            user-select: none;
            cursor: pointer;
            flex-wrap: nowrap;

        }
    }

    &__selections {
        width: 100%;
        height: 100%;

        grid-row: 3;
        grid-column: 1 / span 2;
        text-align: left;
        padding-left: 15px;
        margin: 0;

        &_first_row {
            margin: 0;
            padding: 0;
            font-weight: 500;
        }

        &__row {
            display: grid;
            grid-template-columns: 40px minmax(0, 1fr);

            :first-child {
                text-align: right;
            }

            :nth-child(2) {
                padding-left: 5px;
            }

            p {
                margin: 0;
                padding: 0;
            }
        }
    }

    &::-webkit-scrollbar-track
    {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        background-color: $white;
    }

    &::-webkit-scrollbar
    {
        width: 6px;
        background-color: $white;
    }

    &::-webkit-scrollbar-thumb
    {
        background-color: $sugarlicious-secondary;
    }
}


.add-to-cart-product {
    &__counter {
        display: grid;
    }

    &__plus-minus {
        justify-self: end;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        height: 40px;
        width: 150px;
        padding: 0;

        user-select: none;

        .qtybutton {
            font-size: 2em;
            line-height: 30px;

            margin: 0;
            padding: 0;
            height: 40px;
            width: 100%;

            cursor: pointer;
            transition: all 0.3s ease 0s;
            text-align: center;

            border: none;

            background-color: $sugarlicious-action;
            color: $white;
        }

        .qtybutton-disabled {
            background-color: $sugarlicious-action-disabled;
        }

        p.cart-plus-minus-box {
            font-size: 14px;
            user-select: none;

            height: 40px;
            line-height: 40px;
            width: 100%;

            margin: 0;
            padding: 0;

            text-align: center;

            color: $black--five;
            border: 1px solid $grey--eight;
            background: transparent none repeat scroll 0 0;
        }
    }
}




.list-categories {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    user-select: none;
}


.list-category {
    background-color: $grey--three;
    box-shadow: 0 4px 6px rgba($black, 0.1);

    width: 100%;
    cursor: pointer;

    display: grid;
    grid-template-rows: 150px auto;

    &__image {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    &__title {
        color: $black--seven;
        font-size: 1.4em;
        padding-top: 15px;
        padding-left: 15px;
        text-align: left;
    }
    &__sub-title {
        color: $grey--four;
        font-size: 1em;
        padding-top: 5px;
        padding-left: 15px;
        padding-bottom: 15px;
        text-align: left;
    }
    &__content {
        .title {
            font-size: 16px;
            line-height: 1.3;
        }
        .category {
            font-size: 12px;
            line-height: 1;

            display: inline-block;

            margin-bottom: 7px;

            color: $grey--four;
        }

        .price {
            font-size: 16px;
            font-weight: 500;
            line-height: 1;

            color: $theme-color--default;

            .main-price {
                text-decoration: line-through;
            }
        }
    }
}

/*=====  End of category-page   ======*/
