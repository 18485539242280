/*=============================================
=            Main            =
=============================================*/

.menu-page-wrapper {

  background-color: $grey--ten;
  .logo {

    padding-top: 5px;

    img {
      margin: 0 auto;
    }
  }
  .text {
    font-size: 16px;
    color: $black--eight;
  }

}

.menu-page {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto minmax(0, 1fr) auto;
  align-items: center;
  justify-items: center;

  max-width: 560px;
  margin: 0 auto;
  height: 100%;

  //background-color: $sugarlicious-primary;

  &__header {
    width: 100%;
    background-color: $white;
    display: grid;
  }

  &__body {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;

    background: $grey--eight;

    &::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: $white;
    }

    &::-webkit-scrollbar
    {
      width: 6px;
      background-color: $white;
    }

    &::-webkit-scrollbar-thumb
    {
      background-color: $sugarlicious-secondary;
    }
  }
}

.header-grid-main {
  grid-row: 1;
  grid-column: 1;
}

.header-grid-install {
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  justify-self: left;

  font-size: 16px;

  margin: 15px;

  cursor: pointer;
  transition: all 0.3s ease 0s;
  text-align: center;

  color: $white;
  border: none;
  background: $sugarlicious-primary;

}
/*=====  End of Main ======*/
