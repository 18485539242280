
/*=============================================
=            category slider            =
=============================================*/

.category-item {
    text-align: center;
    &__image {
        position: relative;

        width: 60px;
        height: 60px;
        margin: 0 auto;
        margin-bottom: 15px;

        border-radius: 50%;
        background-color: $white;
        box-shadow: 0 5px 20px rgba($black--three, 0.05);

        a {
            position: absolute;
            top: 50%;
            left: 50%;

            display: inline-block;

            transform: translate(-50%, -50%);
        }
    }
    &__title {
        a {
            line-height: 1;

            display: block;

            color: $black;
        }
    }
}

/*=====  End of category slider  ======*/

