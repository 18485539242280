/*=============================================
=            Footer            =
=============================================*/
footer {
    width: 100%;
}

.footer-nav-wrapper {
    z-index: 99;
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;

    width: 100%;
    padding: 0;

    border-radius: 0;
    background-color: $white;
    box-shadow: 0 2px 10px rgba($black, 0.16);

    user-select: none;
}

.footer-nav-single {
    position: relative;

    display: block;
    flex-grow: 1;
    height: 85px;


    text-align: center;

    //border-radius: 50%;

    @media #{$extra-extra-small-mobile} {
        height: 60px;
    }

    span {
        font-size: 12px;

        color: $grey--four;
    }
    svg,
    img {
        display: block;

        margin: 0 auto;
        margin-bottom: 5px;
        @media #{$extra-extra-small-mobile} {
            width: 20px;
            height: 20px;
        }
    }

    .menu-wrapper {
        line-height: 1;

        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }

    &:before {
        position: absolute;
        z-index: -1;
        top: -5px;
        left: -5px;

        visibility: hidden;

        width: calc(100% + 10px);
        height: calc(100% + 10px);

        content: "";

        opacity: 0;
        border-radius: 50%;
        background-color: $white;
    }
    @media (hover: hover) {
        &:hover {
            background-color: $sugarlicious-secondary;

            span {
                color: $white;
            }
            svg {
                * {
                    stroke: $white;
                }
            }

            &:before {
                visibility: visible;

                opacity: 1;
            }
        }
    }

}

.cart-has-items {

    animation: scale 2s infinite;

    svg {
        * {
            stroke: $sugarlicious-action;
        }
    }

    @keyframes scale {
        0% {
            opacity: 1;
            transform: scale(1);
        }
        80% {
            opacity: 0.8;
            transform: scale(1.1);
        }
        100% {
            opacity: 1;
            transform: scale(1);
        }
    }

}

.footer-active {
    background-color: $sugarlicious-primary;

    span {
        color: $white;
    }
    svg {
        * {
            stroke: $white;
        }
    }

    &:before {
        visibility: visible;

        opacity: 1;
    }
}

/*=====  End of Footer ======*/
