
/*=============================================
=            welcome area            =
=============================================*/

.welcome-slider-wrapper {
    position: relative;
    .logo {
        img {
            margin: 0 auto;
        }
    }
    .title {
        font-size: 1.5em;
        text-transform: uppercase;
        font-weight: 500;
        color: $sugarlicious-secondary;
    }
    .text {
        font-size: 16px;

        color: $black--eight;
    }

    .welcome-btn {
        font-size: 16px;
        line-height: 1;

        display: block;

        width: 140px;
        margin: 0 auto;
        margin-bottom: 30px;
        padding: 20px;

        color: $white;
        background-color: $sugarlicious-primary;
    }

    .skip-btn {
        color: $grey--nineteen;
        border: none;
        background: none;
    }

    .slick-dots {
        line-height: 1;

        position: absolute;
        bottom: 20px;
        left: 50%;

        transform: translateX(-50%);
        li {
            display: inline-block;

            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }
            &.slick-active {
                button {
                    background-color: $red--two;
                }
            }
            button {
                font-size: 0;

                width: 8px;
                height: 8px;
                padding: 0;

                border: none;
                border-radius: 50%;
                background: none;
                background-color: $grey--four;
            }
        }
    }
}

.single-welcome-slide {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto minmax(0, 1fr) 100px;
    align-items: center;
    justify-items: center;

    height: 100vh;

    &__header {
        width: 100%;
        padding-bottom: 15px;
    }

    &__body,
    &__spacer {
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-color: $grey--ten;
    }

}


.welcome-call-to-action {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

/*=====  End of welcome area  ======*/

