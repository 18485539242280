
/*=============================================
=            landing area            =
=============================================*/

.landing-page-wrapper {

    background-color: $grey--ten;
    .logo {

        padding-top: 5px;

        img {
            margin: 0 auto;
        }
    }
    .text {
        font-size: 16px;
        color: $black--eight;
    }

}

.landing-page-text {
    &__title {
        font-size: 1.5em;
        font-weight: 500;
        color: $white;
        padding-bottom: 15px;
    }

    &__title-container {
        height: 115px;
        display: grid;
        align-items: end;
    }
}

.landing-page {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto minmax(0, 1fr) auto;
    align-items: center;
    justify-items: center;

    max-width: 560px;
    margin: 0 auto;
    height: 100%;

    background-color: $sugarlicious-primary;

    &__header {
        width: 100%;
        background-color: $white;
    }

    &__body {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        justify-items: center;

        &::-webkit-scrollbar-track
        {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
            background-color: $white;
        }

        &::-webkit-scrollbar
        {
            width: 6px;
            background-color: $white;
        }

        &::-webkit-scrollbar-thumb
        {
            background-color: $sugarlicious-secondary;
        }
    }

    &__call-to-action {
        width: 100%;
        color: $sugarlicious-secondary;
        position: relative;
        overflow: hidden;
        z-index: 1;
        padding-top: 50px;
    }


    &__call-to-action::before {
        content: '';
        position: absolute;
        top: 0;
        $left-size: #{'calc(min(560px, 100vw) / 2 - 500px)'};
        left: $left-size;
        display: inline-block;
        width: 1000px;
        height: 1000px;
        padding: 0;
        margin: 0;
        -moz-border-radius: 100%;
        -webkit-border-radius: 100%;
        border-radius: 100%;
        background-color: rgba(255,255,255,0.5);
        z-index: -1;
    }

    .landing-btn {
        font-size: 16px;
        line-height: 1;

        display: block;

        width: 140px;
        margin: 15px auto;
        padding: 20px;

        color: $white;
        background-color: $sugarlicious-action-disabled;
    }

    .btn-active {
        background-color: $sugarlicious-action;
        animation: pulse 2s infinite;
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0 rgba($sugarlicious-action-disabled, 0.7);
        }

        70% {
            box-shadow: 0 0 0 10px rgba($sugarlicious-action-disabled, 0);
        }

        100% {
            box-shadow: 0 0 0 0 rgba($sugarlicious-action-disabled, 0);
        }
    }

}

.landing-page-stores {
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
}

.landing-page-store {
    width: 100%;
    height: 50px;
    background: $white;
    margin-top: 10px;
    margin-bottom: 10px;

    display: grid;
    align-items: center;
    justify-content: center;

    font-size: 1.2em;
    color: $sugarlicious-action;
    text-transform: capitalize;
    font-weight: 400;
    cursor: pointer;
    border: 4px solid transparent;
    box-sizing: content-box;

    user-select: none;
}

.selected-store {
    border: 4px solid $sugarlicious-action;
}

/*=====  End of landing area  ======*/

