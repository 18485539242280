
/*=============================================
=            build-box            =
=============================================*/


.build-box {

    &__instruction {
        display: grid;
        grid-template-columns: minmax(0, 1fr) auto;
    }

    &__button {
        font-size: 16px;

        width: 125px;
        margin: 15px;

        cursor: pointer;
        transition: all 0.3s ease 0s;
        text-align: center;

        color: $white;
        border: none;
        background: $sugarlicious-action;
    }

    &__big-button {
        padding: 10px 15px;
        width: 150px;
    }

    &__secondary {
        background-color: $sugarlicious-primary;
    }
}


/*=====  End of build-box  ======*/

